import React, { Component } from "react";
import * as profileActionCreators from "../actions/profile";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

function mapStateToProps(state) {
  return {
    token: state.auth.token
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(profileActionCreators, dispatch);
}

class SettingsProvider extends Component {

	componentDidUpdate() {
		if (this.props.token){
			this.props.fetchProfile(this.props.token);
		}
	}

  render() {
		return this.props.children
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsProvider);