import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";


const PaymentPendingStateHistoryButton = ({invoice, onClose, children}) => {
    const { i18n } = useTranslation();
	const [ openPaymentPendingStateHistoryDialog, setOpenPaymentPendingStateHistoryDialog] = useState(false);

    const handleCloseDialog = (event, reason) => {
        if (reason !== 'backdropClick') { 
            setOpenPaymentPendingStateHistoryDialog(false);
        }
    }

    let drawedTableRows = [];
    if (invoice) {
        drawedTableRows = invoice.pending_state_history.map((i, index) => {
            return (
                <tr key={index}>
                    <td>{i18n.t(i.change_date)}</td>
                    <td>{i.pending_state.ov_description}</td>
                </tr>
            );
        });
    }
    
    return <>
		<Button onClick={() => setOpenPaymentPendingStateHistoryDialog(true)} color='primary' variant={'text'} style={{padding: 0}} >
			{ children }
		</Button>
		<Dialog open={openPaymentPendingStateHistoryDialog} onClose={handleCloseDialog} scroll={"body"}>
            <DialogContent>
                <Box mb={2}>
                    <Typography variant={"h4"}>{i18n.t('common:text.invoices_dialog_title')}</Typography>
                </Box>
                <table className="table">
                    <thead>
                    <tr>
                        <th>{i18n.t('common:text.invoices_dialog_table_date')}</th>
                        <th>{i18n.t('common:text.invoices_dialog_table_description')}</th>
                    </tr>
                    </thead>
                    <tbody>
                        { drawedTableRows }
                    </tbody>

                </table>
            </DialogContent>
            <DialogActions>
                <Button color={"primary"} variant={"text"} onClick={handleCloseDialog} >
                    {i18n.t('common:text.invoices_dialog_close')}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default PaymentPendingStateHistoryButton;