import React, { createContext, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAvailablePaymentPlatforms, setPayingItemId, setPaymentRelatedObjectType } from "../../actions/payment";
import Cookies from "js-cookie";
import { parseInt } from "lodash";


export const PaymentPlatformContext = createContext({});


/*
 * Feature component
 *
 * Responsible for rendering the payment dialog.
 *
 * - Handles the retrieval of availablePaymentPlatforms reducer actions.
 * - Transmits data to the paymentPlatforms components.
 * - Hides the dialog if the platformName is not available.
*/
const PaymentPlatformProvider = ({ platformName, onPaymentSuccessful, onPaymentError, onClose, children }) => {
  const dispatch = useDispatch();
  const availablePaymentPlatforms = useSelector((state) => state.payment.availablePaymentPlatforms);
  const payingItemId = useSelector((state) => state.payment.payingItemId);

  useEffect(() => {
    // When load verify if the url path has the a paymentIntentId, if so continue with the payment process
    const urlParams = new URLSearchParams(window.location.search);
    const paymentIntentId = urlParams.get("payment_intent");
    if (paymentIntentId) {
      let payingItemIdCookie = Cookies.get("payingItemId"); // Al fer el refresc es perd l'store
      if (!payingItemIdCookie) {
        Cookies.remove("payingItemId");
        Cookies.remove("paymentRelatedObjectType");
        // TODO: Afegir SENTRY, no és normal que tinguis url param de payment_intent i no tinguis la cookie de payingItemId, no podras seguir amb el pagamanet
      } else {
        payingItemIdCookie = parseInt(payingItemIdCookie);
        if (payingItemId && payingItemId !== payingItemIdCookie) { // Cas rar, no hauries de tenir payintItemId al redux, ja que quan tenim paymentIntentId vol dir que han redirigit, per tant el redux store es reseted
          Cookies.remove("payingItemId");
          Cookies.remove("paymentRelatedObjectType");
          // TODO: Afegir SENTRY, si no tenim la mateixa paying item id entre el redux i la cookie és que algo no esta ben lligat
        } else {
          if (payingItemId !== payingItemIdCookie) { // Sempre entrara per aqui
            let paymentRelatedObjectType = Cookies.get("paymentRelatedObjectType"); // Al fer el refresc es perd l'store
            dispatch(setPayingItemId(payingItemIdCookie));
            dispatch(setPaymentRelatedObjectType(paymentRelatedObjectType));
          }
          // Es un intent i ha sigut redirigit Cal que obris el dialog de nou per acabar amb el pagament.
        }
      }
    }
  }, []);

  useEffect(() => {
    if (availablePaymentPlatforms === null) {
      dispatch(fetchAvailablePaymentPlatforms())
    }
  }, [availablePaymentPlatforms]);


  const handleClose = () => {
    dispatch(setPayingItemId(null));
    dispatch(setPaymentRelatedObjectType(null));
    onClose && onClose();
  }

  const handlePaymentSuccess = (details) => {
    //dispatch(receivePaySuccess(details["success"]));
    onPaymentSuccessful && onPaymentSuccessful(details);
  }

  const handlePaymentError = (reason) => {
    //dispatch(receivePayError(reason));
    onPaymentError && onPaymentError(reason);
  }

  const isPaymentPlatformAvailable = () => {
    return !!(availablePaymentPlatforms && availablePaymentPlatforms[platformName]);
  }

  return (
    <PaymentPlatformContext.Provider value={{
      "handlePaymentSuccess": handlePaymentSuccess,
      "handlePaymentError": handlePaymentError,
      "onClose": handleClose,
      "platformName": platformName,
      "isPaymentPlatformAvailable": isPaymentPlatformAvailable
    }}>
      { children }
    </PaymentPlatformContext.Provider>
  )

};


export default PaymentPlatformProvider;
