import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentSummary } from "../../../actions/payment";
import { Button, Dialog } from "@mui/material";
import SuccessPaymentDialogContent from "../../PaymentPlatform/CheckoutFormComps/SuccessPaymentDialogContent";
import PaymentPlatformProvider from "../../PaymentPlatform/PaymentPlatformProvider";


const PaymentPaidSummaryButton = ({ invoice, children }) => {
  const dispatch = useDispatch()
  const { isFetching, success } = useSelector(state => state.payment);
  const [openPaymentSummaryDialog, setOpenPaymentSummaryDialog] = useState(false);

  const handleClickOnSeePaymentSummaryButton = () => {
    setOpenPaymentSummaryDialog(true);
    dispatch(getPaymentSummary(invoice.invoice_id));
  }

  return <>
    <Button color={'primary'} variant={'text'} onClick={handleClickOnSeePaymentSummaryButton}>
      {/* <LoadingAnimation /> } */}
      {children}
    </Button>
    {/* The Dialog component creates a Portal, so if is not opened doesn't exists in the DOM */}
    {success &&
      <PaymentPlatformProvider  onClose={() => setOpenPaymentSummaryDialog(false)} >
        <Dialog open={openPaymentSummaryDialog}>
          <SuccessPaymentDialogContent/>
        </Dialog>
      </PaymentPlatformProvider>
    }
  </>
}

export default PaymentPaidSummaryButton;
